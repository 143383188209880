export const traaceTheme = {
  components: {
    Layout: { headerBg: 'var(--color-darkgreen-base)' },
    Menu: {
      itemBg: 'var(--color-darkgreen-base)',
      subMenuItemBg: 'var(--color-primary)',
      itemSelectedBg: 'var(--color-primary)',
      itemActiveBg: 'var(--color-primary)',
      itemColor: 'var(--color-greengrey-10)',
      itemHoverColor: 'var(--color-greengrey-2)',
      itemSelectedColor: 'var(--color-greengrey-2)'
    },
    DatePicker: {
      controlHeight: 40
    },
    Table: {
      colorFillAlter: '#fff',
      colorLink: '#01826B',
      colorLinkHover: '#01826B',
      colorLinkActive: '#01826B',
      headerBg: '#fff',
      headerSortActiveBg: '#fff'
    },
    Drawer: {
      fontWeightStrong: 400
    },
    Button: {
      borderRadius: 0,
      borderRadiusSM: 0,
      controlOutlineWidth: 0,
      controlHeight: 40
    },
    Pagination: {
      controlHeight: 40
    },
    Tag: {
      borderRadiusSM: 100
    },
    Input: { controlHeight: 40 },
    InputNumber: { controlHeight: 40 },
    TreeSelect: { controlItemBgActive: 'var(--color-greengrey-10)' },
    Dropdown: { controlHeight: 40, controlItemBgActive: 'var(--color-greengrey-10)' },
    Select: { controlHeight: 40, controlItemBgActive: 'var(--color-greengrey-10)' },
    Progress: { colorInfo: 'var(--color-primary)', colorSuccess: 'var(--color-primary)' }
  },
  token: {
    colorPrimary: '#01826B',
    colorPrimaryBg: '#EDF7F4',
    colorPrimaryBgHover: '#EDF7F4',
    colorError: '#98153B',
    colorWarning: '#EF9E3D',
    colorInfo: '#1061C2',
    colorLink: '#01826B',
    colorLinkHover: '#01826B',
    colorLinkActive: '#01826B',
    fontFamily: 'Inter, sans-serif',
    motionDurationFast: '0.075s',
    motionDurationMid: '0.15s',
    motionDurationSlow: '0.225s',
    colorInfoBg: '#F7F9FA',
    colorInfoBorder: '#949998',
    borderRadius: 2
  }
}
