import { CSSProperties } from 'react'
import { secondary2Color } from 'src/common/utils'

export function FootprintRatioBar(props: { ratio: number; style?: CSSProperties; className?: string }) {
  const { ratio } = props

  return (
    <div className={`relative bg-white rounded ${props.className ?? ''}`} style={{ height: '20px', ...props.style }}>
      <div
        className="absolute inset-0 rounded h-full transition-all"
        style={{
          width: `${ratio * 100}%`,
          backgroundColor: secondary2Color
        }}
      />
    </div>
  )
}
