import { datadogRum } from '@datadog/browser-rum'

if (import.meta.env.VITE_ENABLE_RUM === 'true') {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DD_RUM_APPLICATION_ID ?? '',
    clientToken: import.meta.env.VITE_DD_RUM_CLIENT_TOKEN ?? '',
    site: 'datadoghq.eu',
    service: import.meta.env.VITE_DD_RUM_SERVICE ?? '',
    env: import.meta.env.VITE_ENV,
    sessionSampleRate: 100,
    version: import.meta.env.VITE_COMMIT_ID,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [import.meta.env.VITE_REST_API_URL]
  })

  datadogRum.startSessionReplayRecording()
}

export const addDatadogError = (error: Error, context?: object) => {
  if (import.meta.env.VITE_ENABLE_RUM === 'true') {
    datadogRum.addError(error, context)
  }
}
