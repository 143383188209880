import React from 'react'
import {
  Alignment,
  BlockType,
  Bold,
  Colors,
  ImageControl,
  Italic,
  Link,
  Strikethrough,
  Underline
} from 'src/common/editor/controls'

export function EditorToolbarPlugin() {
  return (
    <div className="flex gap-0.5 bg-white border border-solid border-greengrey-20 border-b-0 p-2">
      <BlockType />
      <Bold />
      <Italic />
      <Underline />
      <Strikethrough />
      <Alignment />
      <Link />
      <ImageControl />
      <Colors />
    </div>
  )
}
