import { AuditOutlined, PartitionOutlined } from '@ant-design/icons'
import { Button, Input, List, message } from 'antd'
import { useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { primaryColor, useTypedTranslation } from 'src/common/utils'
import CenteredLoader from 'src/common/utils/CenteredLoader'
import { useAvailableOrganizationsQuery, useGetCurrentAuthUserQuery } from 'src/generated/graphql/types'
import { normalizeStringForSearch } from 'src/common/utils/stringNormalizer'

export function OrganizationsList() {
  const { error, loading, data, client } = useAvailableOrganizationsQuery()
  const { data: currentAuthUser } = useGetCurrentAuthUserQuery({
    context: { adminQuery: true },
    onError: error => {
      for (const graphQLError of error.graphQLErrors) {
        if (graphQLError.extensions?.code === 'UNAUTHORIZED') {
          console.error('User is not authorized in the application', error)
          message.error(t('common.user_is_not_registered_in_application'), 3)
          return
        }
      }

      console.error(error)
      message.error(t('common.error_message'))
    }
  })

  const navigate = useNavigate()
  const [nameFilter, setNameFilter] = useState<string>('')

  const { t } = useTypedTranslation()

  if (loading) {
    return <CenteredLoader />
  }

  if (error || !data) {
    return null
  }

  function generateOrganizationRootPath(organizationId: string) {
    return `o/${organizationId}`
  }

  if (data.listAvailableOrganizations.length === 1) {
    return <Navigate to={generateOrganizationRootPath(data.listAvailableOrganizations[0].id)} />
  }

  async function navigateToOrganization(organizationId: string) {
    await client.clearStore()
    return navigate(generateOrganizationRootPath(organizationId))
  }

  const filteredOrganizations = data.listAvailableOrganizations.filter(organization => {
    if (nameFilter.length === 0) {
      return true
    }

    return normalizeStringForSearch(organization.name).includes(normalizeStringForSearch(nameFilter))
  })

  return (
    <div className="max-w-lg mx-auto mt-8 font-Inter flex flex-col items-justify">
      <img
        src={`${import.meta.env.BASE_URL}images/logo_colors_black.svg`}
        className="m-auto mb-8"
        alt=""
        width="160px"
        height="45px"
      />
      {currentAuthUser?.currentAuthUser.adminRole && (
        <Link to="/admin">
          <Button className="w-full" icon={<AuditOutlined />}>
            {t('organizations_list.admin_button_title')}
          </Button>
        </Link>
      )}
      <div className="py-4 text-sm">{t('organizations_list.message')}</div>
      <div className="flex flex-row gap-2">
        <Input.Search
          autoFocus
          enterButton={nameFilter.length > 0}
          onSearch={async () => {
            await navigateToOrganization(filteredOrganizations[0].id)
          }}
          onChange={e => setNameFilter(e.target.value)}
        />
      </div>
      <div className="border rounded border-solid border-greengrey-5 my-4">
        <List
          dataSource={filteredOrganizations}
          size="large"
          renderItem={organization => (
            <List.Item
              className="cursor-pointer hover:bg-gray-100 transition"
              key={organization.id}
              onClick={async () => await navigateToOrganization(organization.id)}
            >
              <Link to="#">
                <div className="flex flex-row gap-2 items-center">
                  <PartitionOutlined style={{ color: primaryColor }} />
                  {organization.name}
                </div>
              </Link>
            </List.Item>
          )}
        ></List>
      </div>
    </div>
  )
}
