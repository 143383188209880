import { useListAvailableSharedImpactComponentVariantsLazyQuery } from 'src/generated/graphql/types'
import { LCAPresetListItemContents } from 'src/modules/lcas/templateDetails/presets/LCAPresetListItemContents'
import { LCAPreset } from 'src/modules/lcas/templateDetails/presets/model/LCAPreset'
import { ResolvedImpactComponentManagementFunctions } from 'src/modules/lcas/templateDetails/presets/settings/ResolvedImpactComponentEditableItem'
import { generateApolloContextForSharedToken } from 'src/modules/shared/utils/sharingTokenHeaderGenerator'

export function SharedLCAPresetItem(props: {
  preset: LCAPreset
  maxTotalFootprintKgCO2e: number
  sharingToken: string
  onResolvedImpactComponentUpdateRequest: ResolvedImpactComponentManagementFunctions['onResolvedImpactComponentUpdateRequest']
}) {
  const { preset, maxTotalFootprintKgCO2e, sharingToken } = props

  const [listAvailableVariants] = useListAvailableSharedImpactComponentVariantsLazyQuery()
  return (
    <LCAPresetListItemContents
      preset={preset}
      maxTotalFootprintKgCO2e={maxTotalFootprintKgCO2e}
      availableVariantsForComponent={async (componentId: string) => {
        const variants = await listAvailableVariants({
          variables: {
            input: {
              impactComponentId: componentId,
              sharingToken
            }
          },
          context: generateApolloContextForSharedToken({ sharingToken })
        })
        return variants.data?.listAvailableSharedImpactComponentVariants
      }}
      resolvedImpactComponentManagementFunctions={{
        onResolvedImpactComponentUpdateRequest: props.onResolvedImpactComponentUpdateRequest,
        isSavedAutomatically: false
      }}
    />
  )
}
