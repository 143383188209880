import { ExportOutlined } from '@ant-design/icons'
import { CUSTOM_LINK_MENU } from '../routes'
import { useGetCurrentOrganizationCustomLinkQuery } from 'src/generated/graphql/types'
import startsWith from 'lodash/startsWith'
import { Link } from 'react-router-dom'
import { TranslatedValue } from 'src/common/designSystem'

export const useCustomLinkMenu = () => {
  const { data } = useGetCurrentOrganizationCustomLinkQuery()
  if (data === undefined || data.currentOrganization.customLinks.length === 0) {
    return null
  }
  const customLink = data.currentOrganization.customLinks[0]
  const url =
    !startsWith(customLink.url, 'http://') && !startsWith(customLink.url, 'https://')
      ? `https://${customLink.url}`
      : customLink.url

  return {
    key: CUSTOM_LINK_MENU,
    icon: <ExportOutlined />,
    label: (
      <Link to={url} target="_blank">
        <TranslatedValue fields={customLink.title} />
      </Link>
    )
  }
}
