import i18n from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import PreferredLanguageDetector from './PreferredLanguageDetector'

const languageDetector = new LanguageDetector()
languageDetector.addDetector(PreferredLanguageDetector)

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .use(
    resourcesToBackend(language => {
      return import(`./../../locales/${language}/translation.json`)
    })
  )
  .init({
    detection: {
      order: [
        'preferredLanguageOverride',
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'path',
        'subdomain'
      ],
      convertDetectedLanguage: lng => lng.split('-')[0] // en-US -> en
    },
    fallbackLng: ['en'],
    returnEmptyString: false,
    whitelist: ['fr', 'en', 'de'],
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })
