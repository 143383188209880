export enum TopBarValue {
  emission = 'emission',
  financial = 'financial'
}

export enum ChartTabValue {
  global = 'global',
  local = 'local',
  cashFlow = 'cashFlow',
  cumulated = 'cumulated',
  macc = 'macc'
}
