import { ContributingEntity, OrganizationNode } from 'src/provider'

interface PerimeterTreeNode {
  originalNode: OrganizationNode
  children: PerimeterTreeNode[]
  contributingEntities: ContributingEntity[]
}

function buildCompletePerimeterTree(params: {
  allOrganizationNodes: OrganizationNode[]
  allContributingEntities: ContributingEntity[]
}): { [key: string]: PerimeterTreeNode } {
  const { allOrganizationNodes, allContributingEntities } = params
  const result: { [key: string]: PerimeterTreeNode } = {}

  // Initialize mappings of sub-trees
  for (const node of allOrganizationNodes) {
    result[node.id] = {
      originalNode: node,
      children: [],
      contributingEntities: []
    }
  }

  // Add children to sub-trees
  for (const node of allOrganizationNodes) {
    if (!node.parentId) {
      continue
    }
    result[node.parentId]?.children.push(result[node.id])
  }

  for (const entity of allContributingEntities) {
    // Entities may be orphans when focusing only on entities.
    // In that case, they will be added in another step.
    result[entity.organizationNodeId]?.contributingEntities.push(entity)
  }
  return result
}

function exploreOrganizationNodeId(params: {
  organizationNodeId: string
  tree: { [key: string]: PerimeterTreeNode }
  exploredOrganizationNodes: { [key: string]: OrganizationNode }
  exploredContributingEntities: {
    [key: string]: ContributingEntity
  }
}) {
  const { organizationNodeId, tree, exploredContributingEntities, exploredOrganizationNodes } = params
  const treeNode = tree[organizationNodeId]
  if (!treeNode) {
    return
  }
  exploredOrganizationNodes[treeNode.originalNode.id] = treeNode.originalNode
  treeNode.contributingEntities.forEach(e => (exploredContributingEntities[e.id] = e))
  treeNode.children.forEach(n =>
    exploreOrganizationNodeId({
      organizationNodeId: n.originalNode.id,
      tree,
      exploredContributingEntities,
      exploredOrganizationNodes
    })
  )
}

export function getPerimeterIntersection({
  allOrganizationNodes,
  allContributingEntities,
  organizationNodesIds,
  contributingEntitiesIds
}: {
  allOrganizationNodes: OrganizationNode[]
  allContributingEntities: ContributingEntity[]
  organizationNodesIds: string[] | undefined
  contributingEntitiesIds: string[] | undefined
}): {
  filteredOrganizationNodes: OrganizationNode[]
  filteredContributingEntities: ContributingEntity[]
} {
  const shouldDefaultToAllNodesAndEntities = organizationNodesIds === undefined && contributingEntitiesIds === undefined
  if (shouldDefaultToAllNodesAndEntities) {
    return {
      filteredOrganizationNodes: allOrganizationNodes,
      filteredContributingEntities: allContributingEntities
    }
  }

  // Simply go through the filtering tree and resolve IDs through the allOrganizationNodes and allContributingEntities entities
  const filteredOrganizationNodes: {
    [key: string]: OrganizationNode
  } = {}
  const filteredContributingEntities: {
    [key: string]: ContributingEntity
  } = {}

  const tree = buildCompletePerimeterTree({ allOrganizationNodes, allContributingEntities })

  // Add nodes and entities from organizationNodesIds filter
  organizationNodesIds?.forEach(id =>
    exploreOrganizationNodeId({
      organizationNodeId: id,
      tree,
      exploredOrganizationNodes: filteredOrganizationNodes,
      exploredContributingEntities: filteredContributingEntities
    })
  )

  // Add entities from contributingEntitiesIds filter
  if (contributingEntitiesIds) {
    allContributingEntities
      .filter(entity => contributingEntitiesIds.includes(entity.id))
      .forEach(entity => (filteredContributingEntities[entity.id] = entity))
  }

  return {
    filteredOrganizationNodes: Object.values(filteredOrganizationNodes),
    filteredContributingEntities: Object.values(filteredContributingEntities)
  }
}
