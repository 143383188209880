import { message } from 'antd'
import React from 'react'
import { getPerimeterIntersection } from 'src/common/utils/formOrganizationTreeSelect/_components/OrganizationTreeSelectUtils'
import { PermissionName, usePerimeterRelatedToPermissionQuery } from 'src/generated/graphql/types'
import { useTypedTranslation } from '../common/utils'
import { IsLoadingComponent } from 'src/common/designSystem/IsLoadingComponent/IsLoadingComponent'

interface Props {
  children: React.ReactNode
  permission: PermissionName
  filter?: {
    organizationNodesIds: string[]
    contributingEntitiesIds: string[]
  }
}

export interface ContributingEntity {
  id: string
  name: string
  organizationNodeId: string
}

export interface OrganizationNode {
  id: string
  name: string
  parentId: string | null
}

export interface EntitiesNodesContextInterface {
  allOrganizationNodes: OrganizationNode[]
  allContributingEntities: ContributingEntity[]
  ancestorOrganizationNodes: OrganizationNode[]
  loaded: boolean
  refetch: () => void
}

export const EntitiesNodesContext = React.createContext<EntitiesNodesContextInterface>({
  allContributingEntities: [],
  allOrganizationNodes: [],
  ancestorOrganizationNodes: [],
  loaded: false,
  refetch: () => {}
})

export function EntitiesNodesProvider({ children, permission, filter }: Props) {
  const { t } = useTypedTranslation()
  const { data, loading, error, refetch } = usePerimeterRelatedToPermissionQuery({
    variables: {
      getPerimeterRelatedToPermissionInput: {
        permission
      }
    }
  })

  if (loading) {
    return (
      <IsLoadingComponent loading>
        <EntitiesNodesContext.Provider
          value={{
            allOrganizationNodes: [],
            allContributingEntities: [],
            ancestorOrganizationNodes: [],
            loaded: true,
            refetch
          }}
        >
          {children}
        </EntitiesNodesContext.Provider>
      </IsLoadingComponent>
    )
  }

  if (error || !data) {
    message.error(t('common.error_message'))
    console.error(error)
    return null
  }

  let allOrganizationNodes = data.perimeterRelatedToPermission.allowedOrganizationNodes
  let allContributingEntities = data.perimeterRelatedToPermission.allowedContributingEntities
  if (filter) {
    const intersection = getPerimeterIntersection({
      allOrganizationNodes,
      allContributingEntities,
      organizationNodesIds: filter.organizationNodesIds,
      contributingEntitiesIds: filter.contributingEntitiesIds
    })
    allOrganizationNodes = intersection.filteredOrganizationNodes
    allContributingEntities = intersection.filteredContributingEntities
  }
  return (
    <EntitiesNodesContext.Provider
      value={{
        allOrganizationNodes,
        allContributingEntities,
        ancestorOrganizationNodes: data.perimeterRelatedToPermission.ancestorOrganizationNodes,
        loaded: true,
        refetch
      }}
    >
      {children}
    </EntitiesNodesContext.Provider>
  )
}
