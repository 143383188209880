export interface ScreenResolution {
  width: number
  height: number
}

const usualScreenResolutions: ScreenResolution[] = [
  { width: 320, height: 480 },
  { width: 320, height: 568 },
  { width: 360, height: 640 },
  { width: 375, height: 667 },
  { width: 414, height: 736 },
  { width: 414, height: 896 },
  { width: 768, height: 1024 },
  { width: 1024, height: 1366 },
  { width: 1366, height: 768 },
  { width: 1536, height: 864 },
  { width: 1600, height: 900 },
  { width: 1920, height: 1080 },
  { width: 2560, height: 1440 },
  { width: 3840, height: 2160 }
]
export const nearestScreenResolution = (screenResolution: ScreenResolution) => {
  const { width, height } = screenResolution
  const nearest = usualScreenResolutions.reduce((prev, curr) => {
    const prevDistance = Math.sqrt(Math.pow(prev.width - width, 2) + Math.pow(prev.height - height, 2))
    const currDistance = Math.sqrt(Math.pow(curr.width - width, 2) + Math.pow(curr.height - height, 2))
    return prevDistance < currDistance ? prev : curr
  })
  return `${nearest.width}x${nearest.height}`
}
