import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useEffect } from 'react'
import { $patchStyleText, $setBlocksType } from '@lexical/selection'
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  KEY_ENTER_COMMAND
} from 'lexical'

export function ResetStyleOnEnterPlugin() {
  const [editor] = useLexicalComposerContext()
  useEffect(() => {
    editor.registerCommand(
      KEY_ENTER_COMMAND,
      () => {
        editor.update(() => {
          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createParagraphNode())
          }
        })
        editor.update(() => {
          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            $patchStyleText(selection, { color: '#000000' })
          }
        })

        return false
      },
      COMMAND_PRIORITY_EDITOR
    )
  }, [editor])
  return null
}
