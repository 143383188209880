import { ReadOutlined, UserOutlined } from '@ant-design/icons'
import { useOverridableFlag } from 'src/services/KeyShortcuts/Shortcuts/useOverridableFlag'
import { GetProp, Layout, Menu, MenuProps } from 'antd'
import isEmpty from 'lodash/fp/isEmpty'
import last from 'lodash/fp/last'
import { Link, Outlet } from 'react-router-dom'
import Profile from 'src/authentication/Profile'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'

const HELP_KEY = 'help'

import {
  useAnalyzeMenu,
  useMeasureMenu,
  useMyTasksMenu,
  useNavigationState,
  useNetZeroMenu,
  useReduceMenu,
  useSettingsMenu,
  useSupplyChainMenu
} from 'src/navigation/_hooks'
import useAdminMenu from 'src/navigation/_hooks/useAdminMenu'
import { USER_ROUTE } from 'src/navigation/routes'
import { useLCAMenu } from './_hooks/useLCAMenu'
import compactLogo from './logo-mini.svg'
import logo from './logo.svg'
import { useReportingMenu } from 'src/navigation/_hooks/useReportingMenu'
import { useCustomLinkMenu } from 'src/navigation/_hooks/useCustomLinkMenu'

function NavMenu() {
  const { t } = useTypedTranslation()
  const isReduceModuleEnabled = useOverridableFlag(UnleashFlag.ModuleReduceEnabled)
  const myTasksMenu = useMyTasksMenu()
  const mesureMenu = useMeasureMenu()
  const reduceMenu = useReduceMenu({ isReduceModuleEnabled: isReduceModuleEnabled })
  const netZeroMenu = useNetZeroMenu()
  const settingsMenuNext = useSettingsMenu()
  const supplyChainMenu = useSupplyChainMenu()
  const adminMenu = useAdminMenu()
  const analyzeMenu = useAnalyzeMenu()
  const lcaMenu = useLCAMenu()
  const reportingMenu = useReportingMenu()
  const customLinkMenu = useCustomLinkMenu()

  const upMenuItems: GetProp<typeof Menu, 'items'> = [
    myTasksMenu,
    mesureMenu,
    analyzeMenu,
    reduceMenu,
    netZeroMenu,
    supplyChainMenu,
    lcaMenu,
    reportingMenu,
    customLinkMenu
  ]

  const downMenuItems: GetProp<typeof Menu, 'items'> = [
    {
      key: HELP_KEY,
      icon: <ReadOutlined />,
      label: <>{t('menu.docs')}</>,
      onClick: async () => {
        const form = document.createElement('form')
        form.innerHTML = `<input type='hidden' name='authenticate_password' value='TraaceHelp'>`
        form.action = 'https://help.traace.co'
        form.method = 'POST'
        form.target = '_blank'
        document.body.appendChild(form)
        form.submit()
        document.body.removeChild(form)
      }
    },
    {
      key: `${USER_ROUTE}/*`,
      icon: <UserOutlined />,
      label: <Profile />
    },
    settingsMenuNext,
    adminMenu
  ]

  const { selectedKeys, setSelectedKeys, openKeys, setOpenKeys } = useNavigationState({ upMenuItems, downMenuItems })

  const onOpenChange = (keys: string[]) => {
    const openedKeys = isEmpty(keys) ? [] : [last(keys) as string]
    setOpenKeys(openedKeys)
    setSelectedKeys(openedKeys)
  }
  const onMenuClick: GetProp<MenuProps, 'onClick'> = item => {
    if (item.key !== HELP_KEY && item?.keyPath) {
      setOpenKeys(item.keyPath)
      setSelectedKeys([item.key])
    }
  }

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Layout.Sider
          breakpoint="lg"
          collapsedWidth="60"
          style={{ width: 'inherit', minHeight: '100vh' }}
          width={250}
          className="z-10 traace-sider-menu"
        >
          <div className="h-full flex flex-col fixed overflow-y-auto overflow-x-hidden" style={{ width: 'inherit' }}>
            <div className="text-center py-10">
              <a href="/" className="">
                <img src={logo} className="m-auto hidden lg:block" alt="" width="112px" height="20px" />
                <img src={compactLogo} className="m-auto lg:hidden" alt="" width="20px" />
              </a>
            </div>
            <div className="flex flex-col h-full justify-between">
              <div>
                <Menu
                  mode="inline"
                  selectable={false}
                  className="border-r-0"
                  items={upMenuItems}
                  openKeys={openKeys}
                  selectedKeys={selectedKeys}
                  onOpenChange={onOpenChange}
                  onClick={onMenuClick}
                />
              </div>
              <div>
                <Menu
                  mode="inline"
                  selectable={false}
                  className="border-r-0"
                  items={downMenuItems}
                  openKeys={openKeys}
                  selectedKeys={selectedKeys}
                  onOpenChange={onOpenChange}
                  onClick={onMenuClick}
                />
                <div className="text-center flex-shrink w-full pb-1">
                  <Link
                    target="_blank"
                    className="text-xs text-white text-opacity-50 hover:text-opacity-100 "
                    to="/policies/app/privacy"
                  >
                    {t('menu.privacy')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Layout.Sider>
        <Layout>
          <Layout.Content>
            <div className="w-full">
              <Outlet />
            </div>
          </Layout.Content>
        </Layout>
      </Layout>
    </>
  )
}

export default NavMenu
