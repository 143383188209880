import { Select, message } from 'antd'
import { useEffect, useState } from 'react'
import { estimateFootprint } from 'src/modules/lcas/templateDetails/presets/computation/footprintEstimator'
import { FootprintRatioBar } from './FootprintRatioBar'
import { SettingsSlider } from './SettingsSlider'
import { generateURL, useTypedTranslation } from 'src/common/utils'
import { DeleteButton } from 'src/common/designSystem'

export interface ResolvedImpactComponentManagementFunctions {
  onResolvedImpactComponentDeletionRequest?: (params: { resolvedImpactComponentToDeleteId: string }) => void
  onResolvedImpactComponentUpdateRequest?: (params: {
    resolvedImpactComponentToUpdateId: string
    variant?: { id: string }
    value?: number
    debounced: boolean
  }) => void
  isSavedAutomatically: boolean
}

export interface ResolvedImpactComponentVariant {
  id: string
  name: string
}

export type ResolvedImpactComponentAvailableVariantsFetcher = (
  componentId: string
) => Promise<ResolvedImpactComponentVariant[] | undefined>

export function ResolvedImpactComponentEditableItem(props: {
  resolvedComponent: {
    id: string
    value: number
    variant: {
      id: string
      name: string
      emissionFactor: {
        valuePerReferenceUnit: number
        referenceUnitEntity: {
          conversionFactor: number
        }
      }
      impactComponent: {
        id: string
        name: string
        iconName: string
        color: string
        unitEntity: {
          id: string
          shortLabel: string
          conversionFactor: number
        }
        minValue: number
        maxValue: number
      }
    }
  }
  totalFootprintKgCO2e: number
  managementFunctions?: ResolvedImpactComponentManagementFunctions
  availableVariantsForComponent: ResolvedImpactComponentAvailableVariantsFetcher
}) {
  const { resolvedComponent, totalFootprintKgCO2e } = props

  const { t } = useTypedTranslation()

  const [availableVariants, setAvailableVariants] = useState<ResolvedImpactComponentVariant[] | undefined | null>(
    undefined
  )

  useEffect(() => {
    if (availableVariants === undefined) {
      setAvailableVariants(null)
      props.availableVariantsForComponent(resolvedComponent.variant.impactComponent.id).then(variants => {
        if (!variants) {
          console.error('error fetching available variants')
          message.error(t('common.error_message'))
          setAvailableVariants(null)
          return
        }
        setAvailableVariants(variants)
      })
    }
  }, [availableVariants, resolvedComponent.variant.impactComponent.id])

  const [value, setValue] = useState(resolvedComponent.value)
  const [variant, setVariant] = useState<ResolvedImpactComponentVariant>(resolvedComponent.variant)

  const footprintKgCO2e = estimateFootprint({
    value,
    impactFactor: resolvedComponent.variant.emissionFactor,
    component: resolvedComponent.variant.impactComponent
  })

  // Use 1 as the ceiling to take into account possible desynchronization with the remote data
  const footprintRatio = Math.min(footprintKgCO2e / totalFootprintKgCO2e, 1)

  return (
    <div className="flex flex-row gap-2 items-center">
      <div className="flex-grow flex flex-col gap-2">
        <div className="flex flex-row gap-1 items-center">
          <div
            className="rounded border border-gray-300 border-solid"
            style={{
              backgroundColor: resolvedComponent.variant.impactComponent.color,
              width: '18px',
              height: '18px'
            }}
          />
          <img
            width={18}
            height={18}
            className="shrink-0"
            src={generateURL({ iconName: resolvedComponent.variant.impactComponent.iconName })}
            alt=""
          />
          <div>
            <span className="font-medium">{`${resolvedComponent.variant.impactComponent.name} - `}</span>
            {availableVariants ? (
              <Select
                size="small"
                onChange={value => {
                  const newVariant = availableVariants.find(variant => variant.id === value)!
                  setVariant(newVariant)
                  if (props.managementFunctions) {
                    props.managementFunctions.onResolvedImpactComponentUpdateRequest?.({
                      resolvedImpactComponentToUpdateId: resolvedComponent.id,
                      variant: newVariant,
                      debounced: false
                    })
                  }
                }}
                popupMatchSelectWidth={false}
                value={variant.id}
                options={availableVariants.map(variant => ({
                  value: variant.id,
                  label: variant.name
                }))}
              />
            ) : (
              <span>{resolvedComponent.variant.name}</span>
            )}
          </div>
        </div>
        <FootprintRatioBar ratio={footprintRatio} style={{ width: '400px' }} />
        <div className="text-xs">{`${footprintKgCO2e.toLocaleString()} kgCO2e`}</div>
      </div>
      <SettingsSlider
        displayUnit={resolvedComponent.variant.impactComponent.unitEntity.shortLabel}
        min={resolvedComponent.variant.impactComponent.minValue}
        max={resolvedComponent.variant.impactComponent.maxValue}
        value={value}
        onChange={value => {
          setValue(value)
          if (props.managementFunctions) {
            props.managementFunctions.onResolvedImpactComponentUpdateRequest?.({
              resolvedImpactComponentToUpdateId: resolvedComponent.id,
              value,
              debounced: true
            })
          }
        }}
      />
      {props.managementFunctions?.onResolvedImpactComponentDeletionRequest && (
        <DeleteButton
          size="small"
          confirmTitle={t('common.delete_confirm_message')}
          tooltipTitle={t('common.delete')}
          onConfirm={() => {
            props.managementFunctions?.onResolvedImpactComponentDeletionRequest?.({
              resolvedImpactComponentToDeleteId: resolvedComponent.id
            })
          }}
        />
      )}
    </div>
  )
}
