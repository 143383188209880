import React, { ReactNode } from 'react'
import { DecoratorNode, SerializedLexicalNode, Spread } from 'lexical'
import { ImageDecoratorProvider } from 'src/common/editor/nodes/image/ImageDecoratorProvider'
import { Image } from 'src/common/editor/nodes/image/Image'
import type { AlignType } from 'src/common/editor/nodes/image/Image'

type SerializedImageNode = Spread<
  {
    src: string
    align: AlignType
    width: number | undefined
    height: number | undefined
  },
  SerializedLexicalNode
>

export class ImageNodeDecorator extends DecoratorNode<ReactNode> {
  __src: string
  __align: AlignType = 'center'
  __width: number | undefined
  __height: number | undefined

  static getType(): string {
    return 'imageDecorator'
  }

  static clone(node: ImageNodeDecorator): ImageNodeDecorator {
    return new ImageNodeDecorator(
      { src: node.__src, align: node.__align, width: node.__width, height: node.__height },
      node.getKey()
    )
  }

  static importJSON(data: SerializedImageNode): ImageNodeDecorator {
    return $createImageNodeDecorator({ src: data.src, align: data.align, width: data.width, height: data.height })
  }

  constructor(
    {
      src,
      align = 'center',
      width,
      height
    }: { src: string; align: AlignType; width: number | undefined; height: number | undefined },
    key?: string
  ) {
    super(key)
    this.__src = src
    this.__align = align
    this.__width = width
    this.__height = height
  }

  createDOM(): HTMLElement {
    return document.createElement('div')
  }

  updateDOM(): false {
    return false
  }

  decorate(): ReactNode {
    return (
      <ImageDecoratorProvider>
        <Image
          nodeKey={this.getKey()}
          src={this.__src}
          align={this.getAlign()}
          width={this.getWith()}
          height={this.getHeight()}
        />
      </ImageDecoratorProvider>
    )
  }

  setSize(width: number, height: number) {
    const self = this.getWritable()
    self.__width = width
    self.__height = height
  }

  getWith() {
    const self = this.getLatest()
    return self.__width
  }

  getHeight() {
    const self = this.getLatest()
    return self.__height
  }

  setAlign(align: AlignType) {
    const self = this.getWritable()
    self.__align = align
  }

  getAlign(): AlignType {
    const self = this.getLatest()
    return self.__align
  }

  exportJSON(): SerializedImageNode {
    const self = this.getLatest()
    return {
      type: this.getType(),
      version: 1,
      src: self.__src,
      align: self.__align,
      width: self.__width,
      height: self.__height
    }
  }
}

export function $createImageNodeDecorator(
  {
    src,
    align,
    width,
    height
  }: { src: string; align: AlignType; width: number | undefined; height: number | undefined },
  key?: string
): ImageNodeDecorator {
  return new ImageNodeDecorator({ src, align, width, height }, key)
}

// export function $isImageNodeDecorator(node: LexicalNode | null | undefined): node is ImageNodeDecorator {
//   return node instanceof ImageNodeDecorator
// }
