import React from 'react'
import { ANALYZE_MENU } from '../routes'
import { LayoutOutlined } from '@ant-design/icons'
import { OrganizationLink } from '../OrganizationLink'
import { usePermissions } from './index'
import { useTypedTranslation } from 'src/common/utils'

export function useAnalyzeMenu() {
  const { canAccessAnalyze } = usePermissions()
  const { t } = useTypedTranslation()

  if (!canAccessAnalyze) {
    return null
  }

  return {
    key: `${ANALYZE_MENU}/analysis`,
    icon: <LayoutOutlined />,
    label: <OrganizationLink to="/analyses/dashboards/list">{t('menu.analyze')}</OrganizationLink>
  }
}
