export function estimateTotalFootprintForPreset(params: {
  preset: {
    resolvedComponents: {
      value: number
      variant: {
        impactComponent: {
          unitEntity: {
            conversionFactor: number
          }
        }
        emissionFactor: {
          valuePerReferenceUnit: number
          referenceUnitEntity: {
            conversionFactor: number
          }
        }
      }
    }[]
  }
}) {
  const { preset } = params
  const totalFootprintKgCO2e = preset.resolvedComponents.reduce((acc, resolvedComponent) => {
    return (
      acc +
      estimateFootprint({
        value: resolvedComponent.value,
        component: resolvedComponent.variant.impactComponent,
        impactFactor: resolvedComponent.variant.emissionFactor
      })
    )
  }, 0)
  return totalFootprintKgCO2e
}

export function estimateFootprint(params: {
  value: number
  component: {
    unitEntity: {
      conversionFactor: number
    }
  }
  impactFactor: {
    valuePerReferenceUnit: number
    referenceUnitEntity: {
      conversionFactor: number
    }
  }
}) {
  const { value, component, impactFactor } = params
  const footprint =
    (value * component.unitEntity.conversionFactor * impactFactor.valuePerReferenceUnit) /
    impactFactor.referenceUnitEntity.conversionFactor
  return footprint
}
