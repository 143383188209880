import React, { useRef } from 'react'
import { useImageDecoratorContext } from 'src/common/editor/nodes/image/useImageDecoratorContext'
import classNames from 'classnames'

export function ResizeSquare({ className }: { className?: string }) {
  const squareRef = useRef<HTMLDivElement>(null)
  const { setStartResize, setInitialX } = useImageDecoratorContext()
  return (
    <div
      ref={squareRef}
      onMouseDown={e => {
        setStartResize(true)
        setInitialX(e.clientX)
      }}
      className={classNames('w-3 h-3 border border-solid bg-white opacity-80 border-greengrey-80', className)}
    />
  )
}
