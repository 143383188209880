import { PermissionName, useGetPermissionsQuery } from 'src/generated/graphql/types'

export function usePermissions() {
  const { error, data, loading } = useGetPermissionsQuery()
  const permissions = data?.getPermissions ?? []
  const canAccessAnalyze = permissions.includes(PermissionName.ReadAnalysisDashboard)
  const canReadAnalyses = permissions.includes(PermissionName.ReadAnalyses)
  const canReadUpload = permissions.includes(PermissionName.ReadUpload)
  const canReadAction = permissions.includes(PermissionName.ReadAction)
  const canManageAction = permissions.includes(PermissionName.ManageAction)
  const canReadTrajectoryAndForecast = permissions.includes(PermissionName.ReadTrajectoryAndForecast)
  const canManageTrajectories = permissions.includes(PermissionName.ManageTrajectory)
  const canReadActionPlan = permissions.includes(PermissionName.ReadActionPlan)
  const canManageOrganizationHierarchy = permissions.includes(PermissionName.ManageOrganizationHierarchy)
  const canReadAudit = permissions.includes(PermissionName.ManageOrganizationSettings)
  const canReadCategory = permissions.includes(PermissionName.ReadCategory)
  const canReadImpactFactor = permissions.includes(PermissionName.ReadEmissionFactor)
  const canManageDataPointTags = permissions.includes(PermissionName.ManageDatapointTag)
  const canReadUser = permissions.includes(PermissionName.ReadUser)
  const canManageOrganizationSettings = permissions.includes(PermissionName.ManageOrganizationSettings)
  const canReadRoles = permissions.includes(PermissionName.ReadRole)
  const canReadSuppliers = permissions.includes(PermissionName.ReadSuppliers)
  const canManageSuppliers = permissions.includes(PermissionName.ManageSuppliers)
  const canManageSupplierAttributesAndScoring = permissions.includes(PermissionName.ManageSupplierAttributesAndScoring)
  const canManageCampaigns = permissions.includes(PermissionName.ManageCampaign)
  const canAssignCampaignTask = permissions.includes(PermissionName.AssignCampaignTask)
  const canOverrideCampaignTaskResponse = permissions.includes(PermissionName.OverrideCampaignTaskResponse)
  const canCreateDashboards = permissions.includes(PermissionName.CreateAnalysisDashboard)
  const canManageEmissionFactor = permissions.includes(PermissionName.ManageEmissionFactor)
  const canManageAnalyses = permissions.includes(PermissionName.ManageAnalyses)
  const canManageLCAs = permissions.includes(PermissionName.ManageLcas)
  const canManageEmissionsItemsCategory = permissions.includes(PermissionName.ManageCategory)
  const canManageContributingEntityIndicatorData = permissions.includes(
    PermissionName.ManageContributingEntityIndicatorData
  )
  const canManageUsers = permissions.includes(PermissionName.ManageUser)
  const canManageContributingSource = permissions.includes(PermissionName.ManageContributingSource)
  const canUploadFile = permissions.includes(PermissionName.UploadFile)
  const canReadEmissionItemDetail = permissions.includes(PermissionName.ReadEmissionItemDetail)

  return {
    permissionsLoading: loading,
    permissionsData: data,
    permissionsError: error,
    canAccessAnalyze,
    canReadAnalyses,
    canReadUpload,
    canReadAction,
    canManageAction,
    canReadTrajectoryAndForecast,
    canManageTrajectories,
    canReadActionPlan,
    canManageOrganizationHierarchy,
    canReadAudit,
    canReadCategory,
    canReadImpactFactor,
    canManageDataPointTags,
    canReadUser,
    canManageOrganizationSettings,
    canReadRoles,
    canReadSuppliers,
    canManageSuppliers,
    canManageSupplierAttributesAndScoring,
    canManageCampaigns,
    canAssignCampaignTask,
    canOverrideCampaignTaskResponse,
    canCreateDashboards,
    canManageEmissionFactor,
    canManageAnalyses,
    canManageLCAs,
    canManageEmissionsItemsCategory,
    canManageContributingEntityIndicatorData,
    canManageUsers,
    canManageContributingSource,
    canUploadFile,
    canReadEmissionItemDetail
  }
}
