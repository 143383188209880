import mixpanel from 'mixpanel-browser'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { isProductionEnvironment } from 'src/common/utils/environment'
import './common/utils/i18n'
import './index.css'
import 'src/services/rum'
import { AntConfigProvider } from 'src/provider'
import { Root } from 'src/app/Root'
/**
 * DO NOT DELETE THIS IMPORT. It loads polyfill for dinosaurs.
 */
import './polyfills'

const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN
if (mixpanelToken) {
  mixpanel.init(mixpanelToken, { debug: !isProductionEnvironment() })
}

const router = createBrowserRouter([{ path: '*', element: <Root /> }])

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <AntConfigProvider>
      <RouterProvider router={router} />
    </AntConfigProvider>
  </React.StrictMode>
)
