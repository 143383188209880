import { NavigateOptions, To, useNavigate, useParams } from 'react-router-dom'
import isString from 'lodash/fp/isString'
import { generateOrganizationLink } from 'src/navigation/GenerateOrganizationLink'

export function useOrganizationNavigate() {
  const navigate = useNavigate()
  const { organizationId } = useParams<'organizationId'>()
  return function (to: To | string, options?: NavigateOptions): void {
    if (isString(to)) {
      navigate(generateOrganizationLink(to, organizationId), options)
    } else {
      const pathname = to.pathname ? generateOrganizationLink(to.pathname, organizationId) : undefined
      navigate({ ...to, pathname }, options)
    }
  }
}
