import { Modal, Switch } from 'antd'
import { useUnleashOverrideContext } from 'src/services/KeyShortcuts/Shortcuts/useUnleashOverrideContext'

export const UnleashFlagsOverrideModal = ({ open, onCancel }: { open: boolean; onCancel: () => void }) => {
  const { flags: flagSessionOverride, setFlag: setFlagSessionOverride } = useUnleashOverrideContext()
  return (
    <Modal title="Unleash Flags Override" open={open} onCancel={onCancel} footer={null}>
      {flagSessionOverride?.map(flag => (
        <div key={flag.name}>
          <Switch
            className="m-2"
            value={flag.enabled}
            onChange={val => {
              setFlagSessionOverride(flag.name, val)
            }}
          />
          {flag.name.replaceAll('_', ' ')}
        </div>
      ))}
    </Modal>
  )
}
