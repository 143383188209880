import { useFilterField } from 'src/common/hooks'

export const useSortOrder = (setCurrentPage: (pageNumber: number) => void) => {
  const pathName = window.location.pathname
  const [order, setOrder] = useFilterField<'ASC' | 'DESC'>({
    urlKey: 'order',
    defaultValue: 'ASC',
    type: 'string',
    sessionStorageKey: `${pathName}/order`
  })

  const [sort, setSort] = useFilterField<string>({
    urlKey: 'sort',
    type: 'string',
    sessionStorageKey: `${pathName}/sort`
  })

  return {
    order,
    setOrder: (order: 'ASC' | 'DESC') => {
      setOrder(order)
      setCurrentPage(1)
    },
    sort,
    setSort: (sort: string) => {
      setSort(sort)
      setCurrentPage(1)
    }
  }
}

export const usePagination = (params: {
  limit: number
  offsetKey?: string
}): {
  offset?: number
  currentPage?: number
  setCurrentPage: (pageNumber: number) => void
  limit?: number
  setLimit: (limit: number) => void
} => {
  const { limit: defaultLimit, offsetKey } = params

  const pathName = window.location.pathname

  const [offset, setOffset] = useFilterField<number>({
    defaultValue: 0,
    type: 'number',
    urlKey: offsetKey ?? 'offset',
    sessionStorageKey: `${pathName}/offset`
  })

  const [limit, setLimit] = useFilterField<number>({
    defaultValue: defaultLimit,
    type: 'number',
    urlKey: 'limit',
    sessionStorageKey: `${pathName}/limit`
  })

  return {
    offset,
    currentPage: offset !== undefined && limit !== undefined ? offset / limit + 1 : undefined,
    setCurrentPage: (pageNumber: number) => setOffset(Math.round((pageNumber - 1) * (limit ?? defaultLimit))),
    limit,
    setLimit
  }
}
