import { DeleteOutlined, DeleteTwoTone } from '@ant-design/icons'
import { Button, Popconfirm, Tooltip } from 'antd'
import { RenderFunction } from 'antd/lib/_util/getRenderPropValue'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { TooltipPlacement } from 'antd/lib/tooltip'
import React from 'react'
import { useTypedTranslation } from '../../utils'

type DeleteButtonProps = {
  confirmTitle: React.ReactNode | RenderFunction
  tooltipTitle?: string
  onConfirm: (e?: React.MouseEvent<HTMLElement>) => void | Promise<void>
  disabled?: boolean
  placement?: TooltipPlacement
  size?: SizeType
  okText?: React.ReactNode
  cancelText?: React.ReactNode
}

function DeleteButton({
  confirmTitle,
  tooltipTitle,
  onConfirm,
  disabled = false,
  placement = 'rightBottom',
  size = 'middle',
  cancelText,
  okText
}: DeleteButtonProps) {
  const { t } = useTypedTranslation()
  return (
    <Popconfirm
      disabled={disabled}
      placement={placement}
      title={confirmTitle}
      onConfirm={e => {
        e?.stopPropagation()
        onConfirm(e)
      }}
      onCancel={e => e?.stopPropagation()}
      okText={okText || t('common.delete_button')}
      okType="danger"
      cancelText={cancelText || t('common.cancel_button_title')}
    >
      <Tooltip title={tooltipTitle || t('common.delete')} placement="bottomLeft">
        <Button
          shape="circle"
          onClick={e => e.stopPropagation()}
          aria-label={tooltipTitle || t('common.delete')}
          icon={disabled ? <DeleteOutlined /> : <DeleteTwoTone />}
          disabled={disabled}
          size={size}
        />
      </Tooltip>
    </Popconfirm>
  )
}

export default DeleteButton
