export const primaryColor = '#0BD89D'
export const successColor = '#52c41a'
export const dangerColor = '#f5222d'
export const warningColor = '#f5bf42'

export const secondary2Color = '#FFCF97'

export const negativeColor = '#98153B'
export const positiveColor = '#099D72'

export enum UncertaintyColors {
  A = '#05A102',
  B = '#66E006',
  C = '#F5C61D',
  D = '#FF5C00',
  UNKNOWN = '#B8B8B8'
}
