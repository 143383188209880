import {
  ApolloCache,
  DefaultContext,
  DocumentNode,
  MutationHookOptions,
  MutationTuple,
  TypedDocumentNode,
  useMutation
} from '@apollo/client'
import debounce from 'lodash/debounce'

const debounceMutation = debounce((mutation, options) => {
  const controller = new AbortController()

  return mutation({
    ...options,
    options: {
      context: {
        fetchOptions: {
          signal: controller.signal
        }
      }
    }
  })
}, 500)

export const useDebouncedMutation = <TData, TVariables, TCache extends ApolloCache<any> = ApolloCache<any>>(
  GQL: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, DefaultContext>
): MutationTuple<TData, TVariables, DefaultContext, TCache> => {
  const [mutation, rest] = useMutation<TData, TVariables>(GQL, options)

  return [options => debounceMutation(mutation, options), rest]
}
