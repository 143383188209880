import { SlidersOutlined } from '@ant-design/icons'
import { Button, Divider } from 'antd'
import React, { ReactNode, useState } from 'react'
import { convertNumber, useTypedTranslation } from 'src/common/utils'
import { HorizontalBarChart } from 'src/modules/lcas/charts/HorizontalBarChart'
import { estimateTotalFootprintForPreset } from 'src/modules/lcas/templateDetails/presets/computation/footprintEstimator'
import { LCAPreset } from 'src/modules/lcas/templateDetails/presets/model/LCAPreset'
import { LCAPresetSettings } from 'src/modules/lcas/templateDetails/presets/settings/LCAPresetSettings'
import {
  ResolvedImpactComponentAvailableVariantsFetcher,
  ResolvedImpactComponentManagementFunctions
} from 'src/modules/lcas/templateDetails/presets/settings/ResolvedImpactComponentEditableItem'
import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { Counter } from 'src/common/designSystem/Counter'

export function LCAPresetListItemContents(props: {
  preset: LCAPreset
  maxTotalFootprintKgCO2e?: number
  extra?: ReactNode
  settingsExtra?: ReactNode
  resolvedImpactComponentManagementFunctions?: ResolvedImpactComponentManagementFunctions
  availableVariantsForComponent: ResolvedImpactComponentAvailableVariantsFetcher

  // if not null, enables a deeplink to the preset details page
  detailsLinkContents?: {
    templateId: string
    presetId: string
  }
}) {
  const { t } = useTypedTranslation()
  const { preset, maxTotalFootprintKgCO2e, extra, settingsExtra, detailsLinkContents } = props
  const [areSettingsVisible, setAreSettingsVisible] = useState(false)
  const totalFootprintKgCO2e = estimateTotalFootprintForPreset({ preset })
  const presetWithSortedResolvedComponents = {
    ...preset,
    // Sort resolved components by impact component name
    // Due to limitations in mikro orm, this is easier that way
    resolvedComponents: [...preset.resolvedComponents].sort((a, b) => {
      return a.variant.impactComponent.name.localeCompare(b.variant.impactComponent.name)
    })
  }
  return (
    <div className="p-4 bg-gray-100  border border-gray-200 border-solid rounded">
      <div className="flex flex-col items-justify">
        <div className="flex flex-col w-full">
          <div className="flex flex-row gap-2 items-center w-full">
            <div className="text-lg font-medium">
              {detailsLinkContents ? (
                <OrganizationLink
                  to={`lcaTemplates/${detailsLinkContents.templateId}/presets/${detailsLinkContents.presetId}`}
                >
                  {preset.name}
                </OrganizationLink>
              ) : (
                preset.name
              )}
            </div>
            {extra}
          </div>
          <Counter
            value={totalFootprintKgCO2e}
            duration={0.3}
            className="text-sm"
            formattingFn={(value: number) => `${convertNumber(Math.round(value))} kgCO2e`}
          />
        </div>
        <HorizontalBarChart
          maxTotalFootprintKgCO2e={maxTotalFootprintKgCO2e}
          preset={presetWithSortedResolvedComponents}
        />
        <div>
          <Button
            type="default"
            size="small"
            icon={<SlidersOutlined className="-rotate-90" />}
            onClick={() => {
              setAreSettingsVisible(!areSettingsVisible)
            }}
          >
            {areSettingsVisible
              ? t('lca_templates.presets.list.close_button_title')
              : t('lca_templates.presets.list.edit_button_title')}
          </Button>
        </div>
      </div>
      {areSettingsVisible && (
        <>
          <Divider />
          <LCAPresetSettings
            preset={presetWithSortedResolvedComponents}
            extra={settingsExtra}
            resolvedImpactComponentManagementFunctions={props.resolvedImpactComponentManagementFunctions}
            availableVariantsForComponent={props.availableVariantsForComponent}
          />
        </>
      )}
    </div>
  )
}
