import find from 'lodash/fp/find'
import last from 'lodash/fp/last'
import { useEffect, useState } from 'react'
import { Location, matchPath, useLocation } from 'react-router-dom'
import { GetProp, Menu } from 'antd'

type NavigationStateProps = {
  upMenuItems: GetProp<typeof Menu, 'items'>
  downMenuItems: GetProp<typeof Menu, 'items'>
}

type KeyMatch = {
  key: string
  isMatching: boolean
  parent?: string
}

function isPathMatching(params: { location: Location; path: string }): boolean {
  const {
    location: { pathname },
    path
  } = params
  const match = matchPath(path, pathname)
  return !!match
}

function buildKeysArray(params: { location: Location; menu: GetProp<typeof Menu, 'items'> }): KeyMatch[] {
  let keys = <KeyMatch[]>[]
  const { location, menu } = params
  for (const menuItem of menu) {
    if (!menuItem) continue
    if ('children' in menuItem) {
      keys = [...keys, ...buildKeysArray({ location, menu: menuItem.children as GetProp<typeof Menu, 'items'> })]
    } else {
      const rawKey = menuItem.key as string
      const splittedKeys = rawKey.split(' ')
      const isSubMenu = splittedKeys.length > 1
      const keyPath = isSubMenu ? last(splittedKeys)! : rawKey
      const isMatching = isPathMatching({ location, path: keyPath })
      keys = [...keys, { key: rawKey, isMatching, parent: isSubMenu ? splittedKeys[0] : undefined }]
    }
  }
  return keys
}

export function useNavigationState({ upMenuItems, downMenuItems }: NavigationStateProps) {
  const { pathname } = useLocation()
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  const [openKeys, setOpenKeys] = useState<string[]>([])
  const location = useLocation()
  const menu = [...upMenuItems, ...downMenuItems]
  const menuKeys = buildKeysArray({ location, menu })
  useEffect(() => {
    const routeMatch = find<KeyMatch>({ isMatching: true })(menuKeys)
    if (routeMatch) {
      setSelectedKeys([routeMatch.key])
      setOpenKeys(routeMatch.parent ? [routeMatch.parent] : [])
    }
  }, [pathname, JSON.stringify(menuKeys)])

  return {
    openKeys,
    setOpenKeys,
    selectedKeys,
    setSelectedKeys
  }
}
