import React, { useState } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { $getSelection, $isTextNode, FORMAT_TEXT_COMMAND } from 'lexical'
import { Button } from 'antd'
import { BoldOutlined } from '@ant-design/icons'

export const Bold = () => {
  const [isSelectionBold, setSelectionBold] = useState(false)
  const [editor] = useLexicalComposerContext()

  const onChange = () => {
    editor.getEditorState().read(() => {
      const selection = $getSelection()
      if (!selection) return
      const hasNodeBoldFormat = selection
        .getNodes()
        .map(node => $isTextNode(node) && node.hasFormat('bold'))
        .some(Boolean)
      setSelectionBold(hasNodeBoldFormat)
    })
  }
  return (
    <>
      <Button
        title="Bold"
        icon={<BoldOutlined style={{ color: isSelectionBold ? 'var(--color-primary)' : '' }} />}
        type="text"
        size="small"
        aria-checked={isSelectionBold}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
        }}
      />
      <OnChangePlugin onChange={onChange} />
    </>
  )
}
