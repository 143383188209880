import React, { memo } from 'react'
import CountUp, { CountUpProps } from 'react-countup'

type CounterProps = {
  value?: number
  renderWhenChange?: string | null
} & Omit<CountUpProps, 'end'>

export const Counter = memo(
  function Counter({ value, className, ...props }: CounterProps) {
    return <CountUp className={className} duration={1} end={value as number} decimals={4} {...props} />
  },
  (prevProps, nextProps) => {
    return prevProps.value === nextProps.value && prevProps.renderWhenChange === nextProps.renderWhenChange
  }
)
