import React, { useState } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { $getSelection, $isTextNode, FORMAT_TEXT_COMMAND } from 'lexical'
import { Button } from 'antd'
import { UnderlineOutlined } from '@ant-design/icons'

export const Underline = () => {
  const [isSelectionUnderline, setSelectionUnderline] = useState(false)
  const [editor] = useLexicalComposerContext()

  const onChange = () => {
    editor.getEditorState().read(() => {
      const selection = $getSelection()
      if (!selection) return
      const hasNodeItalicFormat = selection
        .getNodes()
        .map(node => $isTextNode(node) && node.hasFormat('underline'))
        .some(Boolean)
      setSelectionUnderline(hasNodeItalicFormat)
    })
  }
  return (
    <>
      <Button
        title="Underline"
        icon={<UnderlineOutlined style={{ color: isSelectionUnderline ? 'var(--color-primary)' : '' }} />}
        type="text"
        size="small"
        aria-checked={isSelectionUnderline}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
        }}
      />
      <OnChangePlugin onChange={onChange} />
    </>
  )
}
