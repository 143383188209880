import React, { useEffect, useRef, useState } from 'react'
import { useNumberFormat } from 'src/common/utils'
import { useImageDecoratorContext } from 'src/common/editor/nodes/image/useImageDecoratorContext'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getNodeByKey } from 'lexical'
import classNames from 'classnames'
import { ResizeSquare } from 'src/common/editor/nodes/image/ResizeSquare'
import { Radio } from 'antd'
import { AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined } from '@ant-design/icons'
import { ImageNodeDecorator } from 'src/common/editor/nodes/image/ImageNodeDecorator'

export type AlignType = 'left' | 'center' | 'right'

export function Image({
  nodeKey,
  src,
  align,
  width,
  height
}: {
  nodeKey: string
  src: string
  align: AlignType
  width: number | undefined
  height: number | undefined
}) {
  const ref = useRef<HTMLImageElement>(null)
  const { toNumber } = useNumberFormat()
  const [isHover, setIsHover] = useState(false)
  const { startResize, setStartResize, initialX, setInitialX } = useImageDecoratorContext()
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (ref.current) {
      const currentImageBoundingClientRect = ref.current.getBoundingClientRect()
      if (
        (currentImageBoundingClientRect.width !== width || currentImageBoundingClientRect.height !== height) &&
        currentImageBoundingClientRect.width !== 0
      ) {
        editor.update(() => {
          const node = $getNodeByKey(nodeKey)
          if (node instanceof ImageNodeDecorator) {
            node.setSize(currentImageBoundingClientRect.width, currentImageBoundingClientRect.height)
          }
          return true
        })
      }
    }
  }, [ref.current, editor, width, height])

  const handleMouseMove = (e: MouseEvent) => {
    if (startResize) {
      editor.update(() => {
        const node = $getNodeByKey(nodeKey)
        if (node instanceof ImageNodeDecorator) {
          const deltaX = e.clientX - initialX
          node.setSize((width ?? 0) + deltaX, (height ?? 0) + deltaX / ((width ?? 0) / (height ?? 0)))
          setInitialX(e.clientX)
        }
        return true
      })
    }
  }
  const handleMouseUp = () => {
    setStartResize(false)
  }
  useEffect(() => {
    if (editor.isEditable()) {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [editor, startResize, width, height, initialX])
  return (
    <div
      className={classNames('flex', {
        'justify-center': align === 'center',
        'justify-start': align === 'left',
        'justify-end': align === 'right'
      })}
    >
      <div
        className={classNames('relative', {
          'border border-dashed': editor.isEditable()
        })}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {editor.isEditable() && (
          <>
            <div className="absolute -translate-x-1/3 -translate-y-1/3">
              <ResizeSquare className="cursor-nwse-resize" />
            </div>
            <div className="absolute right-0 translate-x-1/3 -translate-y-1/3">
              <ResizeSquare className="cursor-nesw-resize" />
            </div>
            <div className="absolute right-0 bottom-0 translate-x-1/3 translate-y-1/3">
              <ResizeSquare className="cursor-nwse-resize" />
            </div>
            <div className="absolute bottom-0 -translate-x-1/3 translate-y-1/3">
              <ResizeSquare className="cursor-nesw-resize" />
            </div>
            <div
              className={classNames('absolute left-1/2 transition-transform -translate-x-1/2 -translate-y-1/3', {
                hidden: !isHover
              })}
            >
              <Radio.Group
                value={align}
                size="small"
                onChange={e => {
                  editor.update(() => {
                    const node = $getNodeByKey(nodeKey)
                    if (node instanceof ImageNodeDecorator) {
                      node.setAlign(e.target.value as AlignType)
                    }
                    return true
                  })
                }}
                className="block"
              >
                <Radio.Button value="left">
                  <AlignLeftOutlined />
                </Radio.Button>
                <Radio.Button value="center">
                  <AlignCenterOutlined />
                </Radio.Button>
                <Radio.Button value="right">
                  <AlignRightOutlined />
                </Radio.Button>
              </Radio.Group>
            </div>
            {(width ?? 0) > 100 && (height ?? 0) > 100 && (
              <div className="absolute bottom-2 right-2 bg-white opacity-80 p-1 text-xs">
                {toNumber(width ?? 0, { maximumFractionDigits: 0 })}&nbsp;x&nbsp;
                {toNumber(height ?? 0, { maximumFractionDigits: 0 })}
              </div>
            )}
          </>
        )}
        <img alt="" src={src} ref={ref} width={width ?? 'auto'} height={height ?? 'auto'} />
      </div>
    </div>
  )
}
