import logoFooter from 'src/modules/shared/_components/logo_footer.png'

export function SharedLCAFooter() {
  return (
    <div className="flex flex-col gap-2 items-center justify-center text-white py-16">
      <div className="text-sm">Built with 💚 by</div>
      <div style={{ height: '26px' }}>
        <a href="https://traace.co" target="_blank" rel="noreferrer">
          <img src={logoFooter} alt="Traace logo" className="h-full" />
        </a>
      </div>
    </div>
  )
}
