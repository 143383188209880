import { PreferredLanguageLocalStorageKey } from './PreferredLanguageDetector'

type notationProps = 'standard' | 'scientific' | 'engineering' | 'compact' | undefined

const toPercentDefaultOptions = { maximumFractionDigits: 0 }
const toNumberDefaultOptions = { maximumFractionDigits: 2, notation: 'standard' as notationProps }
const toMoneyDefaultOptions = { minimumFractionDigits: 0, maximumFractionDigits: 2 }

export function useNumberFormat() {
  const language = localStorage.getItem(PreferredLanguageLocalStorageKey)
  const compactNumberFormatter = Intl.NumberFormat(language || 'en', { notation: 'compact' })
  const toNumber = (
    value: number,
    options?: {
      maximumFractionDigits: number
      notation?: notationProps
    }
  ): string => Intl.NumberFormat(language || 'en', { ...toNumberDefaultOptions, ...options }).format(value)
  return {
    toPercent: (value: number, options?: { maximumFractionDigits: number }): string => {
      const percentFormatter = new Intl.NumberFormat(language || 'en', {
        style: 'percent',
        ...toPercentDefaultOptions,
        ...options
      })

      return percentFormatter.format(value)
    },
    toNumber,
    toCompactNumber: (value: number): string => compactNumberFormatter.format(value),
    toMoney: (
      value: number,
      options?: {
        maximumFractionDigits?: number
        notation?: notationProps
      }
    ) => {
      return Intl.NumberFormat(language || 'en', {
        style: 'currency',
        currency: 'EUR',
        ...toMoneyDefaultOptions,
        ...options
      }).format(value)
    }
  }
}
