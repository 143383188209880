import isNil from 'lodash/fp/isNil'
import { useEffect, useState } from 'react'
import { useStateWithSessionStorage } from 'src/common/hooks/useStateWithSessionStorage'
import { useArrayStateWithURLStorage } from 'src/common/hooks/useStateWithURLStorage'
import isString from 'lodash/fp/isString'

type ValueType = 'string' | 'boolean' | 'number' | 'string[]'
type AllowedTypes = string | boolean | number | string[]

interface FilterField<T extends AllowedTypes> {
  urlKey?: string
  sessionStorageKey?: string
  defaultValue?: T
  type?: ValueType
}
function isArrayOfStrings(value: AllowedTypes): value is string[] {
  return Array.isArray(value) && value.every(item => isString(item))
}

const anyToStringArray = (value?: AllowedTypes): string[] | undefined => {
  if (value === undefined || isArrayOfStrings(value)) {
    return value
  }
  return [`${value}`]
}
export function stringArrayToAny<T extends AllowedTypes>(
  value: string[] | undefined | null,
  type: ValueType
): T | undefined {
  if (isNil(value) || value.length === 0) {
    return undefined
  }
  switch (type) {
    case 'string':
      return value[0] as T
    case 'boolean':
      return (value[0] === 'true') as T
    case 'number':
      return parseFloat(value[0]) as T
    case 'string[]':
      return value as T
    default:
      return undefined
  }
}

export function useFilterField<T extends AllowedTypes = string[]>({
  urlKey,
  sessionStorageKey,
  defaultValue,
  type = 'string[]'
}: FilterField<T>) {
  const [storedValue, setStoredValue] = useStateWithSessionStorage<string[]>(
    sessionStorageKey,
    anyToStringArray(defaultValue)
  )
  const [urlValue, setURLValue] = useArrayStateWithURLStorage(urlKey, storedValue ?? undefined)
  const [valueReady, setValueReady] = useState(false)
  useEffect(() => {
    if (!valueReady && (urlValue || (defaultValue === undefined && storedValue === null))) {
      setValueReady(true)
      setStoredValue(urlValue ?? null)
    }
    if (urlValue === undefined && valueReady) {
      setURLValue(storedValue ?? undefined)
    }
  }, [urlValue])

  const setValue = (value: T | null) => {
    const stringArrayValue = anyToStringArray(value ?? undefined)
    setURLValue(stringArrayValue)
    setStoredValue(stringArrayValue ?? null)
  }

  return [stringArrayToAny(urlValue, type), setValue, valueReady] as [T | undefined, (value: T | null) => void, boolean]
}
