import { ReactNode } from 'react'
import { DataOriginsContext } from 'src/dataCollection/dataOriginPicker/DataOriginsContext/DataOriginsContext'
import { useDataOriginsQuery } from 'src/generated/graphql/types'

export const DataOriginsContextProvider = ({ children }: { children: ReactNode }) => {
  const { data, loading } = useDataOriginsQuery()

  return (
    <DataOriginsContext.Provider value={{ loading, dataOrigins: data?.listDataOrigins ?? [] }}>
      {loading ? null : children}
    </DataOriginsContext.Provider>
  )
}
