import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export function ConnectV2Redirect() {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    navigate(pathname.replace('/connect_v2', '/imports_v2'))
  }, [pathname, navigate])
  return null
}
