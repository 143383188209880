import { SwapOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { useAvailableOrganizationsQuery } from 'src/generated/graphql/types'
import { useTypedTranslation } from 'src/common/utils/translation-codegen'

export function OrganizationButton(props: { organizationName: string; compactMode?: boolean }) {
  const { data } = useAvailableOrganizationsQuery()
  const { t } = useTypedTranslation()
  const { organizationName, compactMode } = props
  const navigate = useNavigate()

  return (
    <div className="flex flex-row gap-2">
      <div>{organizationName}</div>
      {data &&
        data.listAvailableOrganizations.length > 1 &&
        (compactMode ? (
          <SwapOutlined onClick={() => navigate('/')} className="border border-solid rounded p-px hover:opacity-60" />
        ) : (
          <Link to="/">
            <Button size="small">{t('user_details.switch_organization_button_title')}</Button>
          </Link>
        ))}
    </div>
  )
}
