import { usePermissions } from './_hooks'
import './NavContainer.less'
import NavMenu from 'src/navigation/NavMenu'

export default function NavContainer() {
  const { permissionsLoading, permissionsError, permissionsData } = usePermissions()

  if (permissionsLoading) {
    return null
  }

  if (permissionsError || !permissionsData) {
    console.error(permissionsError)
    return null
  }

  return <NavMenu />
}
