import React, { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react'
import { TrajectoriesQuery } from 'src/generated/graphql/types'
import { FormOrganizationTreeSelectResults } from 'src/common/utils/formOrganizationTreeSelect/_components/OrganizationTreeSelect'
import { ChartTabValue, TopBarValue } from 'src/provider/params'

const DEFAULT_VALUES = {
  topTab: TopBarValue.emission,
  chartTab: ChartTabValue.global,
  entitiesFilter: {
    organizationNodeIds: [],
    contributingEntityIds: []
  },
  selectedTrajectory: undefined,
  setTopTab: () => null,
  setChartTab: () => null,
  setEntitiesFilter: () => null,
  setSelectedTrajectory: () => null
}

type ActionPlanForecastChartProps = {
  topTab: TopBarValue
  chartTab: ChartTabValue
  entitiesFilter: FormOrganizationTreeSelectResults
  selectedTrajectory: TrajectoriesQuery['listTrajectories']['results'][0] | undefined
  setTopTab: Dispatch<SetStateAction<TopBarValue>>
  setChartTab: Dispatch<SetStateAction<ChartTabValue>>
  setEntitiesFilter: Dispatch<SetStateAction<FormOrganizationTreeSelectResults>>
  setSelectedTrajectory: Dispatch<SetStateAction<TrajectoriesQuery['listTrajectories']['results'][0] | undefined>>
}

export const ActionPlanForecastChartContext = createContext<ActionPlanForecastChartProps>(DEFAULT_VALUES)

type ActionPlanForecastChartProviderProps = {
  children: ReactNode
}

const ActionPlanForecastChartProvider = ({ children }: ActionPlanForecastChartProviderProps) => {
  const [topTab, setTopTab] = useState<TopBarValue>(DEFAULT_VALUES.topTab)
  const [chartTab, setChartTab] = useState<ChartTabValue>(DEFAULT_VALUES.chartTab)
  const [entitiesFilter, setEntitiesFilter] = useState<FormOrganizationTreeSelectResults>(DEFAULT_VALUES.entitiesFilter)
  const [selectedTrajectory, setSelectedTrajectory] = useState<
    TrajectoriesQuery['listTrajectories']['results'][0] | undefined
  >()

  return (
    <ActionPlanForecastChartContext.Provider
      value={{
        topTab,
        chartTab,
        entitiesFilter,
        selectedTrajectory,
        setChartTab,
        setTopTab,
        setEntitiesFilter,
        setSelectedTrajectory
      }}
    >
      {children}
    </ActionPlanForecastChartContext.Provider>
  )
}

export default ActionPlanForecastChartProvider
