import { PreferredLanguageLocalStorageKey } from './PreferredLanguageDetector'

export function convertNumber(n: number, options?: Intl.NumberFormatOptions) {
  const language = localStorage.getItem(PreferredLanguageLocalStorageKey)
  return n.toLocaleString(language ?? undefined, options)
}

export function percentFormatter(n: number | string | undefined, decimals = 2): string {
  if (!n && n !== 0) return ''

  return (+(Number(n) * 100).toFixed(decimals)).toString()
}

export function integerFormatter(n: number | string | undefined): string {
  if (!n) return ''

  const numberVal = Number(n)
  return (numberVal > 0 ? Math.floor(numberVal) : Math.ceil(numberVal)).toString()
}

export enum MultipleValues {
  NONE = '',
  KILO = 'k',
  MEGA = 'M'
}

export type Multiple = MultipleValues.KILO | MultipleValues.MEGA | MultipleValues.NONE

export const numberToValueAndUnit: (value: number) => { number: number; unit: Multiple } = value => {
  if (Math.abs(value) < 1000) {
    return { number: value, unit: MultipleValues.NONE }
  } else if (Math.abs(value) < 1000000) {
    return { number: value / 1000, unit: MultipleValues.KILO }
  } else {
    return { number: value / 1000000, unit: MultipleValues.MEGA }
  }
}

export const valueAndUnitToNumber: (value: number, unit: Multiple) => number = (value, unit) => {
  if (unit === MultipleValues.KILO) {
    return value * 1000
  } else if (unit === MultipleValues.MEGA) {
    return value * 1000000
  } else {
    return value
  }
}

export function currencySymbolFromCode(currencyCode: string) {
  const a = 0
  return a.toLocaleString('en', { style: 'currency', currency: currencyCode }).replace('0.00', '')
}
