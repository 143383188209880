import { useOrganizationConfig } from 'src/provider'
import { useOrganizationNavigate } from 'src/navigation/useOrganizationNavigate'
import { OrganizationButton } from 'src/modules/settings/team/team/userDetails/_components/OrganizationButton'

const Profile = () => {
  const { currentUser, organization } = useOrganizationConfig()

  const organizationNavigate = useOrganizationNavigate()

  return (
    <div className="flex space-x-2 h-full">
      <div className="flex flex-col overflow-hidden justify-between py-1 gap-1" style={{ lineHeight: '18px' }}>
        <div
          className="truncate w-full font-medium cursor-pointer"
          onClick={() => organizationNavigate(`/settings/users/${currentUser.authUser.id}`)}
        >
          {currentUser.fullName}
        </div>
        <div className="truncate overflow-y-visible w-full font-light cursor-auto">
          <OrganizationButton organizationName={organization.name} compactMode />
        </div>
      </div>
    </div>
  )
}

export default Profile
