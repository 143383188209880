import { datadogRum } from '@datadog/browser-rum'
import { message } from 'antd'
import isNil from 'lodash/fp/isNil'
import { createContext, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { CurrentUserConfigQuery, useCurrentUserConfigQuery } from 'src/generated/graphql/types'
import { UserInfos } from 'src/models/UserInfos'
import ActionPlanForecastChartProvider from 'src/provider/ActionPlanForecastChartProvider'
import { useTypedTranslation } from '../common/utils'
import CenteredLoader from '../common/utils/CenteredLoader'
import { PreferredLanguageLocalStorageKey } from '../common/utils/PreferredLanguageDetector'

import { OrganizationNavigate } from 'src/navigation/OrganizationNavigate'

type OrganizationConfig = {
  isTraaceAdmin: boolean
  organization: CurrentUserConfigQuery['currentUser']['organization']
  currentUser: UserInfos
  currentUserDefaultPerimeterScope: {
    contributingEntityIds: { id: string; name: string }[]
    organizationNodeIds: { id: string; name: string }[]
  }
}

export const OrganizationConfigContext = createContext<OrganizationConfig | undefined>(undefined)

type OrganizationConfigProviderProps = {
  readonly children: ReactNode
}

export function ConfigProvider({ children }: OrganizationConfigProviderProps) {
  const { t } = useTypedTranslation()
  const navigate = useNavigate()
  const { loading, error, data } = useCurrentUserConfigQuery({
    onError: error => {
      for (const graphQLError of error.graphQLErrors) {
        if (graphQLError.extensions?.code === 'FORBIDDEN') {
          console.error('User does not belong to the organization', error)
          message.error(t('common.user_does_not_belong_to_organization'), 3.2)
          setTimeout(() => navigate('/'), 3000)
          return
        }
      }

      console.error(error)
      message.error(t('common.error_message'))
    }
  })

  if (loading) {
    return <CenteredLoader />
  }

  if (error || !data) {
    return null
  }

  const authUser = data.currentUser.authUser
  const organization = data.currentUser.organization

  if (authUser.language !== localStorage.getItem(PreferredLanguageLocalStorageKey)) {
    if (authUser.language) {
      localStorage.setItem(PreferredLanguageLocalStorageKey, authUser.language)
    } else {
      localStorage.removeItem(PreferredLanguageLocalStorageKey)
      localStorage.removeItem('i18nextLng') // Remove cached value
    }
    window.location.reload()
    return null
  }
  if (!authUser.firstName && !authUser.lastName) {
    return <OrganizationNavigate to="onboarding" />
  }

  const currentUser = new UserInfos(data.currentUser)

  datadogRum.setUser({
    id: currentUser.id,
    name: currentUser.fullName ?? undefined,
    email: currentUser.email,
    organization: {
      id: organization.id,
      name: organization.name
    },
    language: authUser.language
  })

  return (
    <OrganizationConfigContext.Provider
      value={{
        isTraaceAdmin: !isNil(authUser.adminRole),
        currentUser,
        organization,
        currentUserDefaultPerimeterScope: {
          contributingEntityIds: data?.currentUser.defaultContributingEntitiesFilter ?? [],
          organizationNodeIds: data?.currentUser.defaultOrganizationNodesFilter ?? []
        }
      }}
    >
      <ActionPlanForecastChartProvider>{children}</ActionPlanForecastChartProvider>
    </OrganizationConfigContext.Provider>
  )
}
