import React, { useEffect } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { Button } from 'antd'
import { FileImageOutlined } from '@ant-design/icons'
import { $createImageNodeDecorator, ImageNodeDecorator } from 'src/common/editor/nodes/image'
import { $createParagraphNode, $insertNodes, $isRootOrShadowRoot } from 'lexical'
import { $wrapNodeInElement } from '@lexical/utils'

export function ImageControl() {
  const [editor] = useLexicalComposerContext()
  const inputRef = React.useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!editor.hasNodes([ImageNodeDecorator])) {
      throw new Error('Image: ImageNode not registered on editor')
    }
  }, [])

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        className="hidden"
        onChange={e => {
          const reader = new FileReader()
          reader.addEventListener('load', event => {
            if (event.target) {
              const img = new Image()
              img.onload = function () {
                editor.update(() => {
                  if (event.target?.result) {
                    const imageNode = $createImageNodeDecorator({
                      src: event.target.result as string,
                      align: 'center',
                      width: img.width,
                      height: img.height
                    })
                    $insertNodes([imageNode])
                    if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
                      $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd()
                    }
                    inputRef.current!.value = ''
                    return true
                  }
                })
              }
              img.src = event.target.result as string
            }
          })
          if (e.target?.files?.[0]) {
            reader.readAsDataURL(e.target?.files?.[0])
          }
        }}
      />
      <Button
        icon={<FileImageOutlined />}
        size="small"
        type="text"
        onClick={() => {
          inputRef?.current?.click()
        }}
      />
    </>
  )
}
