import { useOrganizationSortedLanguages } from 'src/modules/settings/organization/LanguagesSettings/_hooks/useOrganizationSortedLanguages'
import { SortedLanguageContext } from 'src/provider/OrganizationSortedLanguageProvider/SortedLanguageContext'

export const OrganizationSortedLanguageProvider = ({ children }: { children: React.ReactNode }) => {
  const { sortedLanguageCodes, sortedLanguages, ...query } = useOrganizationSortedLanguages()

  return (
    <SortedLanguageContext.Provider value={{ sortedLanguageCodes, sortedLanguages, query }}>
      {children}
    </SortedLanguageContext.Provider>
  )
}
