import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'
import { nearestScreenResolution } from 'src/common/utils/Analytics'
import { PreferredLanguageLocalStorageKey } from 'src/common/utils/PreferredLanguageDetector'
import { UserInfos } from 'src/models/UserInfos'
import { useOrganizationConfig } from 'src/provider'

function initializeMixpanel({
  user,
  organization
}: {
  user: UserInfos
  organization: {
    id: string
    name: string
  }
}) {
  const mixpanelDistinctID = mixpanel.get_distinct_id()
  if (mixpanelDistinctID !== user.id) {
    mixpanel.alias(user.id) // Remap Mixpanel-issued ID to our own ID
    mixpanel.identify(user.id)
  }
  mixpanel.add_group('organization', organization.name)
  mixpanel.add_group('organization_id', organization.id)
  mixpanel.people.set({
    Organization: organization.name,
    $email: user.email,
    $first_name: user.firstName,
    $last_name: user.lastName,
    $name: user.fullName
  })
  mixpanel.track('Language', { language: localStorage.getItem(PreferredLanguageLocalStorageKey) })
  mixpanel.track('Screen Size', {
    screenResolution: `${screen.width}x${screen.height}`,
    windowResolution: `${window.innerWidth}x${window.innerHeight}`,
    pixelRatio: window.devicePixelRatio,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    nearestWindowResolution: nearestScreenResolution({
      width: window.innerWidth,
      height: window.innerHeight
    }),
    nearestScreenResolution: nearestScreenResolution({
      width: screen.width,
      height: screen.height
    })
  })
}

export function useMixpanel() {
  const { currentUser, organization } = useOrganizationConfig()
  useEffect(() => {
    if (import.meta.env.VITE_MIXPANEL_TOKEN) {
      initializeMixpanel({ user: currentUser, organization })
    }
  }, [])
  return null
}
