export function normalizeStringForSearch(str: string) {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}
export const isIncludedNormalized = (word: string, text: string) => {
  const normalizedWord = normalizeStringForSearch(word)
  const normalizedText = normalizeStringForSearch(text)

  return normalizedText.includes(normalizedWord)
}
export const compareStringsNormalized = (str1: string, str2: string) => {
  const normalizedStr1 = normalizeStringForSearch(str1)
  const normalizedStr2 = normalizeStringForSearch(str2)

  return normalizedStr1 === normalizedStr2
}
