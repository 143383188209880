import { ReactNode, useEffect } from 'react'
import { IToggle, useFlags } from '@unleash/proxy-client-react'
import { useParams } from 'react-router-dom'
import { useStateWithSessionStorage } from 'src/common/hooks'
import { UnleashOverrideContext } from 'src/services/KeyShortcuts/Shortcuts/UnleashOverrideContext'

export const UnleashOverrideFlagProvider = ({ children }: { children: ReactNode }) => {
  const actualFlags = useFlags()
  const { organizationId } = useParams<'organizationId'>()
  const [flags, setFlags] = useStateWithSessionStorage<IToggle[]>(`unleash-flags-${organizationId}`, actualFlags)

  useEffect(() => {
    setFlags(actualFlags)
  }, [JSON.stringify(actualFlags)])

  const setFlag = (flagName: string, isEnabled: boolean) => {
    const newFlags = flags?.map(flag => (flag.name === flagName ? { ...flag, enabled: isEnabled } : flag)) ?? []
    setFlags(newFlags)
  }

  return <UnleashOverrideContext.Provider value={{ flags, setFlag }}>{children}</UnleashOverrideContext.Provider>
}
