import { ReactNode } from 'react'
import { message } from 'antd'
import { MessageAPIContext } from 'src/provider/MessageAPIContext'

export const MessageAPIProvider = ({ children }: { children: ReactNode }) => {
  const [messageApi, contextHolder] = message.useMessage()

  return (
    <MessageAPIContext.Provider value={messageApi}>
      {contextHolder}
      {children}
    </MessageAPIContext.Provider>
  )
}
