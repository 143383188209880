import React from 'react'
import { useTypedTranslation } from 'src/common/utils'
import { useOverridableFlag } from 'src/services/KeyShortcuts/Shortcuts/useOverridableFlag'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { MY_TASKS_MENU } from '../routes'
import { CheckSquareOutlined } from '@ant-design/icons'
import { OrganizationLink } from '../OrganizationLink'
import { Badge, GetProp, Menu } from 'antd'
import { useCountCurrentUserActiveCampaignTasksQuery } from 'src/generated/graphql/types'

export function useMyTasksMenu(): GetProp<typeof Menu, 'items'>[number] | null {
  const { t } = useTypedTranslation()
  const isCampaignsRelease = useOverridableFlag(UnleashFlag.ReleaseCampaigns)

  const { data } = useCountCurrentUserActiveCampaignTasksQuery()
  const todoCount = data?.countCurrentUserActiveCampaignTasks || 0

  return isCampaignsRelease
    ? {
        key: `${MY_TASKS_MENU}/todo`,
        icon: <CheckSquareOutlined />,
        label: (
          <>
            <OrganizationLink to="/mytasks">{t('my_tasks.title')}</OrganizationLink>
            {todoCount ? <Badge className="ml-2" count={todoCount} /> : null}
          </>
        )
      }
    : null
}
