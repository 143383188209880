import { useContext } from 'react'
import { MessageAPIContext } from 'src/provider/MessageAPIContext'

export const useMessageAPI = () => {
  const context = useContext(MessageAPIContext)
  if (context === undefined) {
    throw new Error('useMessageAPI must be used within a MessageAPIProvider')
  }
  return context
}
