import React, { useState } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $createHeadingNode, $isHeadingNode, HeadingTagType } from '@lexical/rich-text'
import { $setBlocksType } from '@lexical/selection'
import { $createParagraphNode, $getSelection, $isRangeSelection, $isRootOrShadowRoot } from 'lexical'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { $findMatchingParent } from '@lexical/utils'
import { Select } from 'antd'

export function BlockType() {
  const [editor] = useLexicalComposerContext()
  const [selection, setSelection] = useState<HeadingTagType | 'paragraph'>('paragraph')

  return (
    <>
      <OnChangePlugin
        onChange={() => {
          editor.getEditorState().read(() => {
            const selection = $getSelection()
            if ($isRangeSelection(selection)) {
              const anchorNode = selection.anchor.getNode()
              let element =
                anchorNode.getKey() === 'root'
                  ? anchorNode
                  : $findMatchingParent(anchorNode, e => {
                      const parent = e.getParent()
                      return parent !== null && $isRootOrShadowRoot(parent)
                    })

              if (element === null) {
                element = anchorNode.getTopLevelElementOrThrow()
              }
              if ($isHeadingNode(element)) {
                setSelection(element.getTag())
              } else {
                setSelection('paragraph')
              }
            }
          })
        }}
      />
      <Select
        value={selection}
        size="small"
        popupMatchSelectWidth={false}
        onChange={block => {
          setSelection(block as HeadingTagType | 'paragraph')
          editor.update(() => {
            const selection = $getSelection()
            if ($isRangeSelection(selection)) {
              if (block === 'paragraph') {
                $setBlocksType(selection, () => $createParagraphNode())
              } else {
                $setBlocksType(selection, () => $createHeadingNode(block as HeadingTagType))
              }
            }
          })
        }}
        optionLabelProp="key"
        options={[
          { key: 'normal', label: <span>Normal</span>, value: 'paragraph' },
          { key: 'Heading 1', label: <h1>Heading 1</h1>, value: 'h1' },
          { key: 'Heading 2', label: <h2>Heading 2</h2>, value: 'h2' }
        ]}
      />
    </>
  )
}
