import { useParams } from 'react-router-dom'
import { generateOrganizationLink } from 'src/navigation/GenerateOrganizationLink'

export function useBuildOrganizationLink() {
  const { organizationId } = useParams<'organizationId'>()
  return {
    generateOrganizationLink: (path: string) => {
      return generateOrganizationLink(path, organizationId)
    }
  }
}
