import classNames from 'classnames'
import { Fragment } from 'react/jsx-runtime'

type IsLoadingComponentProps = {
  children: React.ReactNode
  loading: boolean
}
// When a component does not have a loading property, use IsLoadingComponent.
// This wrapper component can be used to display components that are waiting for data while the query is loading.
// Similar to how the loading property works on antd tables, this wrapper re-renders the child component to ensure the 
// new data is displayed even if they are put in a state (eg. defaultValue for Input, initialValues for Form) allowing for
// a better developer experience
// The children components are displayed when loading but with a slighlty lower visibility and are unclickable
// allowing the user to see the component and start reading before the data is loaded thus enhancing perceived page speed
export const IsLoadingComponent = ({ children, loading }: IsLoadingComponentProps) => {
  return (
    <div
      className={classNames({
        'opacity-50 pointer-events-none': loading,
        'opacity-100': !loading,
        'transition-opacity duration-1000': true,
        'relative overflow-hidden': true // Ensures wave effect doesn't overflow
      })}
    >
      {loading && (
        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-200 to-transparent animate-shimmer pointer-events-none opacity-25"></div>
      )}
      <Fragment key={loading ? 'loading' : 'loaded'}>{children}</Fragment>
      <style>{`
        .animate-shimmer {
          background-size: 200% 100%;
          animation: shimmer 2s infinite linear;
        }

        @keyframes shimmer {
          0% {
            background-position: -200% 0;
          }
          100% {
            background-position: 200% 0;
          }
        }
      `}</style>
    </div>
  )
}
