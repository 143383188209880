import { Spin } from "antd";

interface CenteredLoaderProps {
  fullHeight?: boolean
}

export default function CenteredLoader(props: CenteredLoaderProps) {
  const fullHeight = props.fullHeight !== undefined ? props.fullHeight : true
  return <div className={`flex ${fullHeight ? 'h-screen' : ''} justify-center items-center w-full`}>
    <Spin />
  </div>
}