import { PieChartOutlined } from '@ant-design/icons'
import { useOverridableFlag } from 'src/services/KeyShortcuts/Shortcuts/useOverridableFlag'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { OrganizationLink } from '../OrganizationLink'
import { ANALYZE_MENU } from '../routes'
import { usePermissions } from './usePermissions'

export function useLCAMenu() {
  const isLCAModuleEnabled = useOverridableFlag(UnleashFlag.ModuleLCAEnabled)

  const { canManageLCAs } = usePermissions()
  const { t } = useTypedTranslation()

  if (!canManageLCAs || !isLCAModuleEnabled) {
    return null
  }

  return {
    key: `${ANALYZE_MENU}/lcaTemplates`,
    icon: <PieChartOutlined />,
    label: <OrganizationLink to="/lcaTemplates/list">{t('menu.lca')}</OrganizationLink>
  }
}
