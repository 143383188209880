import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { ReactNode } from 'react'

type ContainerProps = {
  children: ReactNode
  className?: string
  breadcrumbItems?: string[]
}

export default function Container({ className, children, breadcrumbItems }: ContainerProps) {
  return (
    <Layout className="px-4 min-h-full mb-10">
      {breadcrumbItems && (
        <Breadcrumb className="my-4">
          {breadcrumbItems.map(item => (
            <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}
      <Content
        className={`site-layout-background p-6 ${className ? className : ''}`}
        style={{
          margin: 0,
          minHeight: 280
        }}
      >
        {children}
      </Content>
    </Layout>
  )
}
