import React, { useState } from 'react'
import { ColorPicker } from 'antd'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getSelection, $isRangeSelection, $isTextNode } from 'lexical'
import { $patchStyleText, $getSelectionStyleValueForProperty } from '@lexical/selection'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'

export function Colors() {
  const [currentColor, setCurrentColor] = useState<string | undefined>('#000000')
  const [editor] = useLexicalComposerContext()
  return (
    <>
      <OnChangePlugin
        onChange={() => {
          editor.update(() => {
            const selection = $getSelection()
            if ($isRangeSelection(selection)) {
              const anchorNode = selection.anchor.getNode()
              if ($isTextNode(anchorNode)) {
                const selectionColor = $getSelectionStyleValueForProperty(selection, 'color', '#000000')
                setCurrentColor(selectionColor)
              }
            }
          })
        }}
      />
      <ColorPicker
        size="small"
        onChange={(color, hex) => {
          setCurrentColor(hex)
          editor.update(() => {
            const selection = $getSelection()
            if ($isRangeSelection(selection)) {
              const anchorNode = selection.anchor.getNode()
              if ($isTextNode(anchorNode)) {
                $patchStyleText(selection, { color: hex })
              }
            }
          })
        }}
        value={currentColor}
      />
    </>
  )
}
