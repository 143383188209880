import { ApolloProvider } from '@apollo/client'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useAuthentifiedApolloClient } from 'src/common/apollo/apolloClient'
import { SearchParamsContextProvider } from 'src/common/providers'
import { OrganizationsList } from 'src/app/multiOrganizations/OrganizationsList'
import { lazy, Suspense } from 'react'
import { CenteredLoader } from 'src/common/utils'

const Admin = lazy(() => import('src/modules/admin/Admin'))

export function MultiOrganizationsRoot() {
  const apolloClient = useAuthentifiedApolloClient()
  return (
    <ApolloProvider client={apolloClient}>
      <SearchParamsContextProvider>
        <Routes>
          <Route index element={<OrganizationsList />} />
          <Route
            path="admin"
            element={
              <Suspense fallback={<CenteredLoader />}>
                <Admin />
              </Suspense>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </SearchParamsContextProvider>
    </ApolloProvider>
  )
}
