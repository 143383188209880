import { ApolloProvider } from '@apollo/client'
import { Route, Routes, useParams } from 'react-router-dom'
import { useAuthentifiedApolloClient } from 'src/common/apollo/apolloClient'
import { Main } from 'src/app/organization/Main'
import { LazyLoadFallback } from 'src/app/LazyLoadFallback'
import React from 'react'

const OnBoarding = React.lazy(() => import('src/app/organization/Onboarding'))

export function Organization() {
  const { organizationId } = useParams<'organizationId'>()
  const client = useAuthentifiedApolloClient({ organizationId })

  return (
    <ApolloProvider client={client}>
      <Routes>
        <Route
          path="onboarding"
          element={
            <LazyLoadFallback>
              <OnBoarding />
            </LazyLoadFallback>
          }
        />
        <Route path="*" element={<Main />} />
      </Routes>
    </ApolloProvider>
  )
}
