import { usePermissions } from 'src/navigation/_hooks'

export function useTeamTabsPermissions(): {
  displayTeamModule: boolean
  displayTab: {
    users: boolean
    roles: boolean
  }
} {
  const { canReadUser, canReadRoles } = usePermissions()
  const displayTab = {
    users: canReadUser,
    roles: canReadRoles
  }
  return {
    displayTeamModule: Object.values(displayTab).some(Boolean),
    displayTab
  }
}
