import {
  ApiOutlined,
  CarryOutOutlined,
  FormOutlined,
  AuditOutlined,
  DashboardOutlined,
  TableOutlined
} from '@ant-design/icons'
import { useOverridableFlag } from 'src/services/KeyShortcuts/Shortcuts/useOverridableFlag'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { OrganizationLink } from '../OrganizationLink'
import {
  CAMPAIGNS_ROUTE,
  CONNECT_ROUTE,
  IMPORTS_ROUTE,
  DATA_COLLECTION_ROUTE,
  MAPPINGS_ROUTE,
  MEASURE_MENU,
  QUESTIONNAIRES_ROUTE
} from '../routes'
import { usePermissions } from './index'
import { GetProp, Menu } from 'antd'

export const useMeasureMenu = () => {
  const { t } = useTypedTranslation()
  const {
    canReadAnalyses,
    canReadUpload,
    permissionsLoading,
    canManageCampaigns,
    canAssignCampaignTask,
    canUploadFile
  } = usePermissions()
  const isCampaignsRelease = useOverridableFlag(UnleashFlag.ReleaseCampaigns)
  const isReleaseCampaignsEnabled = useOverridableFlag(UnleashFlag.ReleaseCampaigns)
  const iConnectV2Enabled = useOverridableFlag(UnleashFlag.ConnectV2)

  if (permissionsLoading) return null
  if (!canReadAnalyses && !canReadUpload) return null

  const children: GetProp<typeof Menu, 'items'> = []
  if (canReadAnalyses) {
    children.push({
      key: `${MEASURE_MENU} ${DATA_COLLECTION_ROUTE}/*`,
      icon: <AuditOutlined />,
      label: (
        <OrganizationLink to="/dataCollection">
          {isCampaignsRelease ? t('menu.analyses') : t('menu.data_collection')}
        </OrganizationLink>
      )
    })
  }

  if (isCampaignsRelease) {
    if (canManageCampaigns) {
      children.push({
        key: `${MEASURE_MENU} ${CAMPAIGNS_ROUTE}/*`,
        icon: <CarryOutOutlined />,
        label: <OrganizationLink to="/campaigns">{t('menu.campaigns')}</OrganizationLink>
      })
      children.push({
        key: `${MEASURE_MENU} ${QUESTIONNAIRES_ROUTE}/*`,
        icon: <FormOutlined />,
        label: <OrganizationLink to="/questionnaires">{t('menu.questionnaires')}</OrganizationLink>
      })
    } else if (canAssignCampaignTask) {
      children.push({
        key: `${MEASURE_MENU} ${CAMPAIGNS_ROUTE}/*`,
        icon: <CarryOutOutlined />,
        label: <OrganizationLink to="/campaigns">{t('menu.campaigns')}</OrganizationLink>
      })
    }
  }

  if (canReadUpload) {
    children.push({
      key: `${MEASURE_MENU} ${CONNECT_ROUTE}/*`,
      icon: <ApiOutlined />,
      label: <OrganizationLink to="/connect">{t('menu.connect')}</OrganizationLink>
    })
    if (iConnectV2Enabled && canUploadFile) {
      children.push({
        key: `${MEASURE_MENU} ${IMPORTS_ROUTE}/*`,
        icon: <ApiOutlined />,
        label: <OrganizationLink to="/imports_v2">{`${t('menu.imports')}`}</OrganizationLink>
      })
    }
  }

  if (isReleaseCampaignsEnabled && canManageCampaigns) {
    children.push({
      key: `${MEASURE_MENU} ${MAPPINGS_ROUTE}`,
      icon: <TableOutlined />,
      label: <OrganizationLink to="/mappings">{t('menu.mappings')}</OrganizationLink>
    })
  }

  return {
    key: MEASURE_MENU,
    icon: <DashboardOutlined />,
    label: t('menu.measure'),
    children
  }
}
