import { Empty } from 'antd'
import { ReactNode } from 'react'
import { LCAPreset } from 'src/modules/lcas/templateDetails/presets/model/LCAPreset'
import { estimateTotalFootprintForPreset } from '../computation/footprintEstimator'
import {
  ResolvedImpactComponentAvailableVariantsFetcher,
  ResolvedImpactComponentEditableItem,
  ResolvedImpactComponentManagementFunctions
} from './ResolvedImpactComponentEditableItem'
import { useTypedTranslation } from 'src/common/utils'

export function LCAPresetSettings(props: {
  preset: LCAPreset
  extra?: ReactNode
  resolvedImpactComponentManagementFunctions?: ResolvedImpactComponentManagementFunctions
  availableVariantsForComponent: ResolvedImpactComponentAvailableVariantsFetcher
}) {
  const { preset } = props
  const { t } = useTypedTranslation()

  const totalFootprintKgCO2e = estimateTotalFootprintForPreset({ preset })

  return (
    <div className="flex flex-col gap-2">
      <div className="italic">
        {props.resolvedImpactComponentManagementFunctions?.isSavedAutomatically
          ? t('lca_templates.impact_components.list.description_autosave')
          : t('lca_templates.impact_components.list.description_no_autosave')}
      </div>
      <div className="flex flex-row gap-2 mb-4">
        <div className="flex-grow font-medium text-lg">
          {t('lca_templates.presets.settings.components_section_title')}
        </div>
        {props.extra}
      </div>
      {preset.resolvedComponents.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t('lca_templates.presets.settings.no_components_message')}
        />
      )}
      {[...preset.resolvedComponents]
        .sort((a, b) => {
          return a.variant.impactComponent.name.localeCompare(b.variant.impactComponent.name)
        })
        .map(resolvedComponent => {
          return (
            <ResolvedImpactComponentEditableItem
              key={resolvedComponent.id}
              resolvedComponent={resolvedComponent}
              totalFootprintKgCO2e={totalFootprintKgCO2e}
              managementFunctions={props.resolvedImpactComponentManagementFunctions}
              availableVariantsForComponent={props.availableVariantsForComponent}
            />
          )
        })}
    </div>
  )
}
