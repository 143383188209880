import { useOverridableFlag } from 'src/services/KeyShortcuts/Shortcuts/useOverridableFlag'
import { useEffect } from 'react'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { UserInfos } from 'src/models/UserInfos'
import { useOrganizationConfig } from 'src/provider'

declare global {
  const plantrack: {
    identify: (
      userId: string,
      params: {
        email?: string
        name?: string
        companyExternalId?: string
        assetExtId?: string // Not supported in our plan
        projectExtId?: string
      }
    ) => void
    track: (
      eventName: string,
      params: {
        // Add a weight if you want to indicate that some actions are more important/valuable than others.
        weight?: number
        info?: any
      }
    ) => void
  }
}

function initializePlanhat(params: { user: UserInfos; organization: { id: string } }) {
  const { user, organization } = params
  plantrack.identify(user.id, {
    email: user.email,
    name: user.fullName ?? undefined,
    companyExternalId: organization.id
  })
  plantrack.track('Loaded Traace', {})
}

function addPlanhatScript() {
  const d = document
  const s = d.createElement('script')
  s.textContent =
    'var plantrack=plantrack||[];!function(){for(var t=["init","identify","track"], a=function(t){ return function(){plantrack.push([t].concat(Array.prototype.slice.call(arguments,0)))}},n=0;n<t.length;n++)plantrack[t[n]]=a(t[n])}(),plantrack.load=function(t,a){plantrack._endpoint=t,a&&plantrack.init(a); var n=document.createElement("script");n.type="text/javascript",n.async=!0, n.src="https://app.planhat.com/analytics/plantrack.min.js"; var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(n,r)}; plantrack.load("https://analytics.planhat.com/analytics/835e499a-1b7b-4051-8e7e-7c58a69f66af");'
  s.type = 'text/javascript'
  d.getElementsByTagName('head')[0].appendChild(s)
}

export function usePlanhat() {
  const isPlanhatEnabled = useOverridableFlag(UnleashFlag.PlanhatIntegrationEnabled)
  const { currentUser, organization } = useOrganizationConfig()

  useEffect(() => {
    if (isPlanhatEnabled) {
      addPlanhatScript()
    }
  }, [isPlanhatEnabled])

  useEffect(() => {
    if (isPlanhatEnabled && Object.prototype.hasOwnProperty.call(window, 'plantrack')) {
      initializePlanhat({ user: currentUser, organization })
    }
  }, [isPlanhatEnabled && Object.prototype.hasOwnProperty.call(window, 'plantrack')])

  return null
}
