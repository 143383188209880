import { BookOutlined, FileDoneOutlined } from '@ant-design/icons'
import { useOverridableFlag } from 'src/services/KeyShortcuts/Shortcuts/useOverridableFlag'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { KNOWLEDGE_BASE_ROUTE, REPORTING_MENU } from 'src/navigation/routes'

export function useReportingMenu() {
  const isReportingModuleEnabled = useOverridableFlag(UnleashFlag.ReportingModule)
  const { t } = useTypedTranslation()

  if (!isReportingModuleEnabled) {
    return null
  }

  return {
    key: REPORTING_MENU,
    icon: <FileDoneOutlined />,
    children: [
      {
        key: `${REPORTING_MENU} ${KNOWLEDGE_BASE_ROUTE}`,
        icon: <BookOutlined />,
        label: <OrganizationLink to="/reporting">{t('menu.reporting_knowledgebase')}</OrganizationLink>
      }
    ],
    label: t('menu.reporting')
  }
}
